



















































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { applicationStoreGetter } from '@/store/application.store';
import Company from '@/models/Company';
import Ride from '@/models/Ride';
import { rideStoreActions, rideStoreGetter } from '@/store/ride.store';
import moment from 'moment';
import BillingRepository from '@/api/repositories/BillingRepository';
import { RepositoryFactory } from '@/api/RepositoryFactory';

const ApplicationStore = namespace('application');
const RideStore = namespace('ride');

const billingRepository: BillingRepository = RepositoryFactory.get('billing');

@Component({
    components: {
        RideTableComponent: () => import(
            /* webpackChunkName: "RideTableComponent" */
            '@/components/RideTable.component.vue')
    }
})
export default class RidesView extends Vue {
    @ApplicationStore.Getter(applicationStoreGetter.SELECTED_COMPANY)
    public selectedCompany!: Company;

    @RideStore.Action(rideStoreActions.LIST)
    public loadAllRides!: (payload: { company: Company }) => Promise<Ride[]>;

    @RideStore.Getter(rideStoreGetter.RIDES)
    public allRides!: Ride[];

    public searchValue: string = '';

    private startMenu: boolean = false;
    private endMenu: boolean = false;
    private startDate: string | null = null;
    private endDate: string | null = null;

    public async created() {
        const today = moment(new Date());
        this.endDate = today.format('yyyy-MM-DD');
        today.set({ date: 1 });
        this.startDate = today.format('yyyy-MM-DD');
        await this.loadAllRides({ company: this.selectedCompany });
    }

    private async onGenerateRideOverview(): Promise<void> {
        if (!this.startDate || !this.endDate) {
            return;
        }

        const response = await billingRepository.generateCompanyPDF({
            company: this.selectedCompany.id,
            from: this.startDate,
            to: this.endDate
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download',
            `${this.selectedCompany.name}_${this.startDate}-${this.endDate}.pdf`);
        document.body.appendChild(link);
        link.click();
    }
}
